<template>
  <el-upload
    class="uploader-pic-wrap"
    refs="singleUpload"
    :action="action"
    :show-file-list="false"
    :before-upload="beforeAvatarUpload"
    :on-success="handleAvatarSuccess"
    :on-progress="handProcess"
    :on-error="handleAvatarError"
    :http-request="requireImg"
    :data="data"
  >
    <div v-if="isUploading" style="padding-top: 24px;">
      <el-progress
        type="circle"
        :percentage="uploadProgress"
        :width="54"
        :stroke-width="2"
      ></el-progress>
    </div>

    <template v-if="!isUploading">
        <i class="el-icon-plus uploader-pic-icon" ></i>
        <div class="uploader-pic-text">
          {{picText}}
        </div>
    </template>

    <div class="el-upload__tip" slot="tip" v-if="tip">
      {{ tip }}
    </div>
  </el-upload>
</template>

<script>

import OSS from "ali-oss";

export default {
  props: {
    // 底下的提示文字，通常提示文件可上传大小的限制
    tip: {
      type: String,
      default() {
        return "";
      }
    },
    // 最大KB大小,默认2MB
    size: {
      type: Number,
      default() {
        return 3072;
      }
    },
    // 是否自动提交,用以控制手动出发，防止用户胡乱传图片。
    // 这个还在改造。还没完成
    autoUpload: {
      type: Boolean,
      default() {
        return true;
      }
    },
    // 大图片压缩率
    quality: {
      type: Number,
      default() {
        return 1;
      }
    },
    // 大图片压缩宽度,默认使用1000的宽度
    qualityWidth: {
      type: Number,
      default() {
        return 1000;
      }
    },
    // 存储路径，格式：path1/path2/  一定要这种格式，不然会出错，层级不限，
    // 该参数可以不传。
    storePath: {
      type: String,
      default() {
        return "";
      }
    },
    picText:{
      type: String,
      default() {
        return "上传图片";
      }
    }
  },
  data() {
    return {
      action: "",
      accept: "image/jpg,image/jpeg,image/png",
      // 是否正在上传图片
      isUploading: false,
      // 上传的进度
      uploadProgress: 0,
      // 上传额外的参数
      data: {
        token: "",
        key: "",
        fname: "",
        "x:name": ""
      }
    };
  },
  methods: {
    /*文件之前的钩子*/
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 3;
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/png';
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过 3MB!');
        return false
      }
      if (!isJPG) {
        this.$message.error('上传文件格式错误!');
        return false
      }
      return true
    },


    /*上传时的钩子*/
    handProcess(event) {
      this.isUploading = true;
      this.uploadProgress = parseInt(event.percent);
    },
    /*覆盖原先上传方式*/
    async requireImg(param) {
      try {
        let file = param.file
        this.isUploading = true
        this.$http.post('/admin/common/get_sts').then(res => {
          if (res.code == 200) {
            this.isUploading = false;
            this.uploadProgress = 0
            this.ossData = res.data
            let fileNames = file.name.split(".");
            let fileExtension = fileNames[fileNames.length - 1];
            var myDate = new Date();
            var year = myDate.getFullYear()
            var month = myDate.getMonth() + 1
            var day = myDate.getDate()
            let newName = String(year) + String(month) + '/' + day + '/' + this.genNonDuplicateID(4);
            const filePath = newName + '.' + fileExtension;

            let client = new OSS({
              region: 'oss-cn-hangzhou',
              secure: true,
              accessKeyId: this.ossData.AccessKeyId,
              accessKeySecret: this.ossData.AccessKeySecret,
              stsToken: this.ossData.SecurityToken,
              bucket: 'catduoduo' // 填写Bucket名称。
            })
            var that = this
            client.put(filePath, file).then(function (r1) {
              console.log('put success: %j', r1);
              that.$emit("success", {
                key: r1.name,
                saveUrl: "/" + r1.name,
              });
            }).catch(function (err) {
              that.isUploading = false
              console.error('error: %j', err);
            });
          }
        })

      } catch (e) {
        this.isUploading = false;
        this.uploadProgress = 0
        this.$message.success('上传失败')
      }
    },
    /*上传成功时的钩子*/
    handleAvatarSuccess() {
      // this.$message.success('上传成功');
    },
    handleAvatarError(err, file, fileList) {
      this.$message.error("文件上传失败");
      this.isUploading = false;
      this.uploadProgress = 0;
      this.$emit("error");
    },
    genNonDuplicateID(randomLength) {
      return Number(
        Math.random()
          .toString()
          .substr(3, randomLength) + Date.now()
      ).toString(36);
    },
  }
};
</script>
<style lang="less">
// 上传图片通用框
.uploader-pic-wrap {
  display: inline-block;
  vertical-align: middle;

  .el-upload {
    display: block;
    width: 100px;
    height: 100px;
    border: 1px dashed #ddd;
    color: #999;

    &:hover {
      border-color: #888;
      color: #333;
    }
  }

  .uploader-pic-icon {
    width: 100px;
    margin-top: 25px;
    font-size: 18px;
  }

  .uploader-pic-text {
    line-height: 12px;
    font-size: 12px;
  }
}
</style>

